/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
import KeyValuePair from "../../components/key-value-pair";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Berufliche Vita"), "\n", React.createElement(KeyValuePair, {
    one: "1986 - 1992",
    two: "Studium Humanmedizin an den Universitäten Hamburg und Köln"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1998",
    two: "Promotion"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1993 - 2003",
    two: "Assistenzarzt an verschiedenen großen Kliniken in Köln und Düsseldorf"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2003 - 2007",
    two: "Ltd. Oberarzt Psychiatrische Abteilung, St. Elisabeth-Krankenhaus Lahnstein"
  }), "\n", React.createElement(KeyValuePair, {
    one: "Seit 2008",
    two: "Niederlassung in eigener Praxis"
  }), "\n", React.createElement(_components.h3, null, "Facharztanerkennungen"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Facharzt für Neurologie"), "\n", React.createElement(_components.li, null, "Facharzt für Psychiatrie und Psychotherapie"), "\n"), "\n", React.createElement(_components.h3, null, "Zusatzweiterbildungen"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "EMDR-Therapeut (EMDRIA)"), "\n", React.createElement(_components.li, null, "Balintgruppenleiter (Deutsche Balintgesellschaft)"), "\n", React.createElement(_components.li, null, "Fachkunde Suchtmedizinische Grundversorgung"), "\n", React.createElement(_components.li, null, "Fachkunde Verkehrsmedizin"), "\n", React.createElement(_components.li, null, "Fachkunde im Strahlenschutz"), "\n", React.createElement(_components.li, null, "Psychoonkologie"), "\n"), "\n", React.createElement(_components.h3, null, "Mitgliedschaften"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Deutsche Gesellschaft für Psychiatrie, Psychotherapie und Nervenheilkunde (DGPPN)"), "\n", React.createElement(_components.li, null, "Deutsche Balintgesellschaft (DBG)"), "\n", React.createElement(_components.li, null, "EMDRIA Deutschland e.V."), "\n"));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
